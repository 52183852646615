import { GameContext } from 'context/Game.context'
import React, { useContext, useEffect, useState } from 'react'
import styles from './Timer.module.scss'

interface TimerProps {
  endGame: () => void
}

const Timer = ({ endGame }: TimerProps) => {
  const [currentTime, setCurrentTime] = useState(20)
  const { setShouldEndGame } = useContext(GameContext)

  useEffect(() => {
    if (currentTime <= 0) {
      setShouldEndGame(true)
      endGame()
      return
    }

    const interval = setInterval(() => {
      setCurrentTime(currentTime - 1)
    }, 1000)

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval)
  }, [currentTime, endGame, setShouldEndGame])

  return (
    <div className={styles.timer}>
      <div className={styles['timer--bar']}>
        <div id="testing" className={styles['timer--bar-fill']} />
      </div>
      <p id={styles['timer--count']}>{currentTime}</p>
    </div>
  )
}

export default Timer

import React, { useEffect, useRef, useState } from 'react'
import { EventOption } from 'types/types'
import styles from './Selector.module.scss'

interface CustomSelectorProps {
  options: EventOption[]
  onChange: (args: EventOption) => void
  value: EventOption
}

const Selector = ({ options, value, onChange }: CustomSelectorProps) => {
  const [showOptions, setShowOptions] = useState(false)
  const selectorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const closeOpenMenus = (e: Event) => {
      if (
        showOptions &&
        selectorRef.current instanceof Element &&
        !selectorRef.current?.contains(e.target as Node)
      ) {
        setShowOptions(false)
      }
    }

    document.addEventListener('mousedown', closeOpenMenus)

    return () => document.removeEventListener('mousedown', closeOpenMenus)
  }, [showOptions])
  return (
    <div>
      <button
        className={
          showOptions ? styles['selector-btn-up'] : styles['selector-btn-down']
        }
        type="button"
        onClick={() => setShowOptions((prev) => !prev)}
      >
        {value.name || 'Choose event'}
        <div
          className={
            showOptions
              ? styles['arrow-wrapper-up']
              : styles['arrow-wrapper-down']
          }
        >
          <i
            className={showOptions ? styles['arrow-up'] : styles['arrow-down']}
          />
        </div>
      </button>
      {showOptions && (
        <div className={styles['selector']} ref={selectorRef}>
          {options.map((option: EventOption) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <button
              key={option.name}
              type="button"
              onClick={() => {
                onChange(option)
                setShowOptions((prev) => !prev)
              }}
              className={styles['option']}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default Selector

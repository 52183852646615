import { AvatarContext } from 'context/Avatar.context'
import { useGenerateAvatar } from 'hooks/Hooks'
import React, { useContext, useEffect, useState } from 'react'
import randomName from 'utils/randomNameUtils'
import LoadingAvatar from './LoadingAvatar/LoadingAvatar'
import Avatar from './Avatar/Avatar'
import styles from './Username.module.scss'

interface UsernameProps {
  storedAvatar: string
  username: string
}

const Username = ({ storedAvatar, username }: UsernameProps) => (
  <div className={styles['avatar-wrapper']}>
    {storedAvatar && (
      <div>
        <Avatar avatar={storedAvatar} />
      </div>
    )}
    <h1 className={styles['username-wrapper']}>{username}</h1>
  </div>
)

export default Username

import React, { useContext, useMemo } from 'react'
import { leaderBoardItem } from 'types/types'
import { EventContext } from 'context/Event.context'
import { useLeaderboard } from 'hooks/Hooks'
// eslint-disable-next-line import/no-extraneous-dependencies
import Cookies from 'js-cookie'
import styles from './Leaderboard.module.scss'

interface LeaderBoardProps {
  close: () => void
}

const Leaderboard = ({ close }: LeaderBoardProps) => {
  const { sortedLeaderboard, rank } = useLeaderboard(
    localStorage.getItem('username') as string
  )

  const { event: eventOption } = useContext(EventContext)
  const { event_id: storedEventId = null, bestScore = 0 } = useMemo(() => {
    const cookieVal = Cookies.get(eventOption.event_id)

    return cookieVal ? JSON.parse(cookieVal) : {}
  }, [])

  return (
    <div className={styles['leaderboard-wrapper']}>
      <div className={styles['leaderboard-content-wrapper']}>
        <div
          role="button"
          tabIndex={0}
          aria-label="close"
          className={styles['leaderboard-close']}
          onClick={() => close()}
        />
        <h1>Leaderboard</h1>
        <h2>{eventOption.name}</h2>
        {sortedLeaderboard &&
          sortedLeaderboard.map((item: leaderBoardItem, index) => {
            if (index >= 5) return null

            return (
              <div
                className={
                  styles[
                    `${
                      rank === index
                        ? 'leaderboard-item-green'
                        : 'leaderboard-item'
                    }`
                  ]
                }
                key={item.id}
              >
                <span className={styles['leaderboard-item--points']}>
                  {index + 1}.
                </span>
                <span>{item.username}</span>
                <span className={styles['leaderboard-item--points']}>
                  {item.score}
                </span>
              </div>
            )
          })}
        {sortedLeaderboard &&
          (rank === -1 || rank >= 5) &&
          storedEventId &&
          // eslint-disable-next-line eqeqeq
          storedEventId == eventOption.event_id && (
            <div className={styles['leaderboard-item-green']} key={6}>
              <span className={styles['leaderboard-item--points']}>
                {rank === -1 ? '' : `${rank + 1} .`}
              </span>
              <span>{localStorage.getItem('username')}</span>
              <span className={styles['leaderboard-item--points']}>
                {bestScore}
              </span>{' '}
            </div>
          )}
        <div className={styles['footer']}>
          Do you want to know more about what we do?
          <br />
          <a
            href="https://linktr.ee/studentbranding"
            target="_blank"
            rel="noreferrer"
          >
            click here
          </a>
        </div>
      </div>
    </div>
  )
}

export default Leaderboard

import Phaser from 'phaser'
import { getSpeed, getXDirection, getYDirection } from 'utils/movmentUtils'
import ballonImg from 'assets/Balloon 3.0.png'

export default class PhaserGameScene extends Phaser.Scene {
  constructor() {
    super('GameScene')
  }

  count = 0

  preload() {
    this.load.image('ballon', ballonImg)
  }

  create() {
    this.matter.world.setBounds()
    const dy = 0
    const dx = 0

    const ballon = this.matter.add.sprite(
      window.innerWidth / 2,
      window.innerHeight / 2,
      'ballon',
      undefined,
      {
        chamfer: { radius: [120, 120, 150, 150] },
      }
    )

    ballon.setInteractive(this.input.makePixelPerfect())
    ballon.setBounce(0.5)
    ballon.setVelocity(dx, dy)

    ballon.setScale(0.75)

    ballon.on('pointerdown', (pointer: any) => {
      const { x, y, width, height } = ballon.getBounds()

      const row = Math.floor(((pointer.y - y) * 3) / height)
      const column = Math.floor(((pointer.x - x) * 3) / width)

      this.count += 1

      this.game.events.emit('clicked', this.count)

      ballon.setVelocity(
        getXDirection(column) * getSpeed(),
        getYDirection(row) * getSpeed()
      )
    })

    this.game.events.on('gameEnd', () => {
      ballon.destroy()
    })
  }
}

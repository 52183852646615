import React, { useContext, useEffect, useRef, useState } from 'react'
import config from 'phaser/config'
import PhaserGameScene from 'phaser/scenes/Game'
import CounterAndTimer from 'components/counterAndTimer/CounterAndTimer'
import { GameContext } from 'context/Game.context'
import GameCountDown from 'components/gameCountDown/GameCountDown'
import videoBackground from '../../assets/moon-phone_1.mp4'
import './game.scss'

interface GameProps {
  endGame: () => void
}

const Game = ({ endGame }: GameProps) => {
  const PhaserGame = useRef<Phaser.Game>()
  const { shouldEndGame, setCount } = useContext(GameContext)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [gameActive, setGameActive] = useState(false)

  const handleLoading = () => setIsLoading(false)

  const startGame = () => {
    setGameActive(true)
  }

  useEffect(() => {
    if (document.readyState === 'complete') {
      handleLoading()
    }

    window.addEventListener('load', handleLoading)
    return () => window.removeEventListener('loading', handleLoading)
  }, [])

  useEffect(() => {
    if (!isLoading && gameActive) {
      if (!PhaserGame.current) {
        PhaserGame.current = new Phaser.Game(
          Object.assign(config, {
            scene: [PhaserGameScene],
          })
        )
      }

      PhaserGame.current.events.on('clicked', (count: number) => {
        setCount(count)
      })
    }
  }, [isLoading, gameActive])

  useEffect(() => {
    if (shouldEndGame) {
      PhaserGame.current?.events.emit('gameEnd')
    }
  }, [shouldEndGame])

  if (!isLoading && gameActive)
    return (
      <>
        <CounterAndTimer endGame={endGame} />
        <div id="game" />
      </>
    )

  return <GameCountDown startGame={startGame} />
}

export default Game

import React, { createContext, ReactNode, useState } from 'react'
import { EventOption } from 'types/types'

interface EventContextProviderProps {
  children: ReactNode
}

interface EventContextProps {
  event: EventOption
  setEvent: (args: EventOption) => void
}

export const EventContext = createContext({} as EventContextProps)

export const EventContextProvider = ({
  children,
}: EventContextProviderProps) => {
  const [event, setEvent] = useState<EventOption>({
    event_id: '0',
    name: '',
  })

  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  )
}
